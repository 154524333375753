import React from 'react'

function Footer() {
  return (
    <footer>
      <h2>TodoList Projesi</h2>
    </footer>
  )
}

export default Footer
