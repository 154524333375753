import React from "react";
import Notes from "../Notes/Notes";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";

function Home() {
  const [user] = useAuthState(auth);
  console.log(user);
  return (
    <div>
      <Notes />
    </div>
  );
}

export default Home;
