// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { doc, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDaTQbCTnZhKyR7pwIK1xNcGayJskY_zvA",
  authDomain: "savvy-night-328600.firebaseapp.com",
  projectId: "savvy-night-328600",
  storageBucket: "savvy-night-328600.appspot.com",
  messagingSenderId: "122814864934",
  appId: "1:122814864934:web:25c04da97f815a460b50e7",
  measurementId: "G-FTLNVFBBWQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); // Bu satırı değiştirin
export const db = getFirestore(app); // Bu satırı değiştirin