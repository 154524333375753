import { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//Containers
import Navbar from "./containers/Navbar/Navbar";
import Footer from "./containers/Footer/Footer";

import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Profile from "./pages/Profile/Profile";
import Notes from "./pages/Notes/Notes";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import MainLayout from "./components/MainLayout";
import AuthLayout from "./components/AuthLayouth";
import AllNotes from "./pages/AllNotes/AllNotes";
import ThemeContext from "./context/themeContext";
const App = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={`app-${theme === "dark" ? theme : ""}`}>
      <Navbar />
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="all-notes" element={<AllNotes />} />
          <Route path="profile" element={<Profile />} />
        </Route>
        <Route path="/auth" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
