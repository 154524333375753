import { useState, useCallback } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
} from "firebase/auth";
import { auth } from "../../firebase";
import { Link } from "react-router-dom";

function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!email || !password || !name) {
        console.log("Lütfen email, şifre ve isim giriniz");
        return;
      }

      try {
        // Kullanıcıyı Firebase Authentication üzerinde oluşturur
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );

        // Kullanıcı adını Firebase Authentication'da ayarlar
        const user = userCredential.user;
        await updateProfile(user, { displayName: name });

        alert("Kaydınız başarıyla yapıldı");
      } catch (error) {
        console.error("Kayıt başarısız:", error.message);
      }
    },
    [name, email, password]
  );

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;
      alert(`Google ile giriş yapıldı: ${user.displayName}`);
    } catch (error) {
      console.error("Google ile giriş başarısız:", error.message);
    }
  };

  return (
    <div className="container Register">
      <div className="form-content d-flex justify-content-center">
        <form onSubmit={handleSubmit}>
          <div className="row justify-content-center">
            <h1>Register</h1>
            <div className="col-12">
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
              />
            </div>
            <div className="col-12">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
            </div>
            <div className="col-12">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
            </div>
            <button type="submit" className="btn-form">
              Kayıt ol
            </button>
            <div className="auth-text d-flex justify-content-between">
              <Link to="/login">
                <span>Giriş yapmak için tıklayınız</span>
              </Link>
              <a href="#" onClick={handleGoogleSignIn}>
                <i class="fa-brands fa-google"></i> Google ile kaydol
              </a>
            </div>
            <div className="auth-text d-flex justify-content-between"></div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
