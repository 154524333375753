import React from "react";
import { Accordion } from "react-bootstrap";
import MyModal from "../../components/modal";
function AllNotes() {
  return (
    <div className="All-Notes">
      <div className="container">
        <MyModal/>
      </div>
    </div>
  );
}

export default AllNotes;
