import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  collection,
  getDocs,
  query,
  where,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import Accordion from "react-bootstrap/Accordion";

function Test() {
  const [show, setShow] = useState(false);
  const [currentUserUid, setCurrentUserUid] = useState(null);
  const [userPosts, setUserPosts] = useState([]);
  const [editText, setEditText] = useState("");
  const [editPostId, setEditPostId] = useState(null);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setCurrentUserUid(user.uid);
      fetchUserPosts(user.uid);
    }
  }, []);

  const handleClose = () => {
    setShow(false);
    setEditText("");
    setEditPostId(null);
  };

  const handleShow = (postId, currentText) => {
    setShow(true);
    setEditText(currentText);
    setEditPostId(postId);
  };

  const fetchUserPosts = async (uid) => {
    try {
      const userPostsRef = collection(db, "users", uid, "notes");
      const userDocs = await getDocs(userPostsRef);

      if (!userDocs.empty) {
        const postsData = userDocs.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserPosts(postsData);
      } else {
        console.error("User has no posts:", uid);
        setUserPosts([]);
      }
    } catch (error) {
      console.error("Error fetching user posts:", error);
    }
  };

  const handleRemovePost = async (postId) => {
    try {
      const postRef = doc(
        collection(db, "users", currentUserUid, "notes"),
        postId
      );
      await deleteDoc(postRef);
      fetchUserPosts(currentUserUid); // Notları günceller
    } catch (error) {
      console.error("Error removing post:", error);
    }
  };

  const handleEditPost = async () => {
    try {
      const postRef = doc(
        collection(db, "users", currentUserUid, "notes"),
        editPostId
      );

      await updateDoc(postRef, { text: editText });
      handleClose();
      fetchUserPosts(currentUserUid); // Notları günceller
    } catch (error) {
      console.error("Error editing post:", error);
    }
  };

  return (
    <>
      <Accordion defaultActiveKey="0">
        {userPosts.map((post, index) => (
          <Accordion.Item key={index} eventKey={index.toString()}>
            <Accordion.Header>{post.title}</Accordion.Header>
            <Accordion.Body className="d-flex align-items-center">
              <p>{post.text} </p>
              <div className="w-100 text-end">
                <Button
                  variant="primary me-3"
                  onClick={() => handleShow(post.id, post.text)}
                >
                  Düzenle
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleRemovePost(post.id)}
                >
                  Kaldır
                </Button>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>

      {/* Modal for editing text */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Düzenle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            rows={3}
            value={editText}
            onChange={(e) => setEditText(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            İptal
          </Button>
          <Button variant="primary" onClick={handleEditPost}>
            Kaydet
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Test;
