import { useState,useContext } from "react";
import { Container, Button } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import ThemeContext from "../../context/themeContext";

const NavbarData = [
  {
    id: 1,
    title: "AnaSayfa",
    icon: "fa-solid fa-house",
    link: "/",
  },
  {
    id: 2,
    title: "Notlarım",
    icon: "fa-solid fa-book",
    link: "/all-notes",
  },
  {
    id: 3,
    title: "Profil",
    icon: "fa-solid fa-user",
    link: "/profile",
  },
  {
    id: 4,
    title: "Kayıt Ol",
    icon: "fa-solid fa-user-plus",
    link: "/auth/register",
  },
  {
    id: 5,
    title: "Giriş Yap",
    icon: "fa-solid fa-user",
    link: "/auth/login",
  },
];

function BasicExample() {
  const [user] = useAuthState(auth);

  const handleSignOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error("Sign out error", error);
    }
  };
  const { theme, setTheme } = useContext(ThemeContext);
  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <i className="fa-solid fa-shop pe-2"></i>
          TodoList Project
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <>
              {NavbarData.map((item, index) =>
                user &&
                (item.title === "Giriş Yap" ||
                  item.title === "Kayıt Ol") ? null : (
                  <Nav.Link href={item.link} id={item.id} key={index}>
                    <i className={`${item.icon} pe-2`}></i>
                    {item.title}
                  </Nav.Link>
                )
              )}
              {user && (
                <Button
                  variant="link"
                  id="navabr-exit-btn"
                  onClick={handleSignOut}
                >
                  <i className="fa-solid fa-arrow-right-from-bracket pe-2"></i>
                  Çıkış Yap
                </Button>
              )}
              <a href="" style={{"padding-top":"8px"}} >
                <input
                  type="checkbox"
                  className="checkbox"
                  id="checkbox"
                  checked={theme === "dark"}
                  onChange={() => setTheme(theme === "dark" ? "light" : "dark")}
                />
                <label htmlFor="checkbox" className="checkbox-label">
                  <i className="fas fa-moon"></i>
                  <i className="fas fa-sun"></i>
                  <span className="ball"></span>
                </label>
              </a>
            </>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicExample;
