import { useState, useCallback } from "react";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth } from "../../firebase";
import { Link } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        if (!email || !password) {
          console.log("Email veya şifre eksik. Lütfen tekrar deneyin");
          return;
        }
        await signInWithEmailAndPassword(auth, email, password);
        const user = auth.currentUser;
        alert("Hoşgeldiniz");
        console.log("Giriş başarıyla yapıldı", user);
        console.log("Kullanıcı Adı:", user.displayName);
        console.log("E-posta Adresi:", user.email);
        console.log("UID:", user.uid);
      } catch (error) {
        console.error("Giriş başarısız:", error.message);
      }
    },
    [email, password]
  );
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;
      alert(`Google ile giriş yapıldı: ${user.displayName}`);
    } catch (error) {
      console.error("Google ile giriş başarısız:", error.message);
    }
  };
  return (
    <div className="container">
      <div className="form-content d-flex justify-content-center">
        <form onSubmit={handleSubmit}>
          <div className="row justify-content-center">
            <h1>Login</h1>
            <div className="col-12">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
            </div>
            <div className="col-12">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
            </div>
            <button type="submit" className="btn-form mb-3">
              Giriş Yap
            </button>
            <a href="#" onClick={handleGoogleSignIn} className="text-white text-end">
              <i class="fa-brands fa-google"></i> Google ile giriş yap
            </a>
            <div className="auth-text d-flex justify-content-between">
              <Link to="/register">
                <span>Kayıt olmak için tıklayınız</span>
              </Link>
              <Link to="/change-password">
                <span>Şifre değiştir</span>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
