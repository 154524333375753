import { useState, useCallback } from "react";
import { Button, Form } from "react-bootstrap";
import MyModal from "../../components/modal";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import { getAuth } from "firebase/auth";

function Notes() {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const { currentUser } = getAuth();

  const handleClose = () => {
    setShow(false);
    setTitle("");
    setText("");
  };

  const handleShow = () => setShow(true);

  const userNotesRef = collection(db, "users", currentUser.uid, "notes");

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      // Kullanıcının notlar koleksiyonuna veriyi ekle
      await addDoc(userNotesRef, {
        title: title,
        text: text,
      });

      // Form submit olduğunda input'ları sıfırla
      setTitle("");
      setText("");

      // Modal'ı kapat
      handleClose();
    },
    [title, text, userNotesRef]
  );

  return (
    <div className="container">
      <div className="row notes-row">
        <div className="col-12">
          <div className="note-header">
            <h4>Not Ekle</h4>
          </div>
          <div className="note-content">
            <div className="col-12 note-col">
              <form className="w-100" onSubmit={handleSubmit}>
                <div className="d-flex flex-column">
                  <div className="col-12 d-flex justify-content-center w-100 pb-4">
                    <input
                      type="text"
                      placeholder="Eklemek istediğiniz notun başlığını giriniz"
                      className="form-control"
                      id="form-control"
                      value={title}
                      onChange={(e) => setTitle(e.currentTarget.value)}
                    />
                  </div>

                  <div className="col-12 d-flex justify-content-center w-100 pb-5">
                    <textarea
                      className="custom-textarea"
                      as="textarea"
                      rows={10}
                      value={text}
                      placeholder={"Notunuzu giriniz"}
                      onChange={(e) => setText(e.currentTarget.value)}
                    ></textarea>
                  </div>
                  <div className="col-12 d-flex justify-content-center w-100 pb-5">
                    <button type="submit" className="btn btn-primary form-btn">
                      Ekle
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="row not-list">
        <div className="not-header">
          <h4>Notlarım</h4>
        </div>
        <div className="not-list-content">
          <div className="not-list-card">
            <MyModal />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notes;
